<template>
  <v-container>
    <v-row v-if="!requests_loaded">
      <v-col v-for="(a, i) in 6" :key="i" cols="12" sm="6">
        <v-skeleton-loader
          type="list-item-three-line, card-heading, actions"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-alert
      class="text-center"
      type="warning"
      dense
      v-if="requests_loaded && requests.length == 0"
    >
      لا يوجد اي طلبات
    </v-alert>

    <v-row class="mb-10" v-if="requests_loaded">
      <v-col v-for="(request, i) in requests" :key="i" cols="12" sm="6">
        <v-list elevation="2" dense>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>person</v-icon>
            </v-list-item-icon>
            <v-list-item-content>{{ request.name }}</v-list-item-content>
          </v-list-item>
          <!--/ customer name -->

          <v-list-item>
            <v-list-item-icon>
              <v-icon>phone_android</v-icon>
            </v-list-item-icon>
            <v-list-item-content>{{ request.phone }}</v-list-item-content>
          </v-list-item>
          <!--/ customer phone -->
          <v-list-item>
            <v-list-item-icon>
              <v-icon>alternate_email</v-icon>
            </v-list-item-icon>
            <v-list-item-content>{{ request.email }}</v-list-item-content>
          </v-list-item>
          <!--/ customer email  -->

          <v-list-item>
            <v-list-item-icon>
              <v-icon>badge</v-icon>
            </v-list-item-icon>
            <v-list-item-content>صورة الرقم القومى</v-list-item-content>
            <v-list-item-action>
              <v-btn @click="show_imgs(request)" color="blue" text>
                عرض الصورة
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <!--/ customer identifier number  -->
          <v-divider class="mt-4 mb-3"></v-divider>
          <v-list-item>
            <v-list-item-content>
              <div class="d-flex">
                <v-btn
                  @click="accept_user(request)"
                  elevation="2"
                  small
                  color="success"
                >
                  <v-icon>check</v-icon>
                </v-btn>
                <v-btn
                  @click="get_delete_id(request)"
                  class="ms-2"
                  elevation="2"
                  small
                  color="error"
                >
                  <v-icon>close</v-icon>
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
          <!--/ customer identifier number  -->
        </v-list>
      </v-col>
    </v-row>

    <v-dialog max-width="500" v-model="dialog.prop">
      <v-card>
        <v-card-title>
          <small
            >العميل
            <span class="primary--text">({{ dialog.name }})</span></small
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-3">
          <div>
            <img
              :src="`https://app.zachi.app/${dialog.front_img}`"
              style="margin: 50px auto 0; width: 100%"
            />
          </div>
          <div>
            <img
              :src="`https://app.zachi.app/${dialog.back_img}`"
              style="margin: 30px auto 20px; width: 100%"
            />
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn @click="dialog.prop = false" color="primary">تم</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="400" v-model="approve">
      <v-card>
        <v-card-title class="justify-center">
          <small>
            تأكيد اضافة (<span class="primary--text">{{ user_name }}</span>
            )</small
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn
            :disabled="load_save"
            :loading="load_save"
            color="primary"
            @click="save()"
            >نعم</v-btn
          >
          <v-btn @click="approve = false" text color="error">الغاء</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="500" v-model="dialog2.prop">
      <v-card>
        <v-card-title>تأكيد الألغاء</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          <h2 class="text-center mb-3 font-weight-medium">
            هل تريد الغاء الطلب؟
          </h2>
          <div class="text-center">
            <v-chip> اسم المستخدم : {{ dialog2.name }} </v-chip>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn
            :disabled="dialog2_loading"
            :loading="dialog2_loading"
            @click="delete_customer"
            color="error"
          >
            الغاء
          </v-btn>
          <v-btn @click="dialog.prop = false" color="success" text>
            الغاء
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "requests",
  data() {
    return {
      load_save: false,
      approve: false,
      requests_loaded: false,
      requests: [],
      user_id: null,
      user_name: "",
      dialog: {
        prop: false,
        front_img: "",
        back_img: "",
        name: "",
      },
      dialog2_loading: false,
      dialog2: {
        prop: false,
        name: "",
        id: null,
      },
    };
  },
  methods: {
    get_requests() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: `requests_reg`,
            method: "get",
          },
        })
        .then((res) => {
          this.requests = res.data.data;
          this.requests_loaded = true;
        }).catch(err=> {
          // console.log(err.response)
        })
    },
    show_imgs(item) {
      this.dialog.front_img = item.front_img;
      this.dialog.back_img = item.back_img;
      this.dialog.name = item.name;

      this.dialog.prop = true;
    },
    accept_user(item) {
      this.user_id = item.id;
      this.user_name = item.name;
      this.approve = true;
    },
    save() {
      if (this.user_id) {
        this.load_save = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: "requests_reg",
              method: "post",
            },
            data: { id: this.user_id },
          })
          .then((res) => {
            this.$store.state.requests = this.$store.state.requests - 1;
            this.get_requests();
            this.load_save = false;
            (this.user_id = null), (this.user_name = null);
            this.approve = false;
          });
      }
    },

    get_delete_id(item) {
      this.dialog2.name = item.name;
      this.dialog2.id = item.id;
      this.dialog2.prop = true;
    },
    delete_customer() {
      this.dialog_loading = true;
      this.$store
        .dispatch("public__request", {
          config: {
            method: "delete",
            url: `users/${this.dialog2.id}`,
          },
        })
        .then((res) => {
          this.get_requests();
          this.$store.state.requests = this.$store.state.requests - 1;
          this.dialog2_loading = false;
          this.dialog2 = {
            prop: false,
            name: "",
            id: null,
          };
        });
    },
  },
  mounted() {
    this.get_requests();
  },
};
</script>
